import { determineChangeServicesButtonState } from "../../../components/Products/ManageProductsServicesChooseFromUtils";
import IAccount from "../../../models/IAccount";
import MspType from "../../../models/MspType";
import IAccountProducts from "../../../models/Products/IAccountProducts";
import IProduct from "../../../models/Products/IProduct";
import IProductFamily from "../../../models/Products/IProductFamily";
import UserRole from "../../../models/UserRole";
import { findAccountById } from "../../../Utilities/accountsHelper";
import { getAuditUsersButtonState, getProductsAvailableToActivate, getProductsAvailableToAssign } from "../../../Utilities/productsHelper";
import { isNotFinance, userHasRole } from "../../../utility";

export interface IProductButtonsSettings {
  shouldShowAssignedSubpartnersButton: boolean;
  shouldShowAssignButton: boolean;
  shouldShowActivateButton: boolean;
  productsToAssignActivateFound: IProduct[];
  shouldAssignButtonBeDisabled: boolean;
  shouldActivateButtonBeDisabled: boolean;
  shouldShowBillingExclusionsButton: boolean;
  shouldBillingExclusionsButtonBeVisible: boolean;
  shouldBillingExclusionsButtonBeDisabled: boolean;
  shouldShowChangeServicesButton: boolean;
  shouldDisableChangeServicesButton: boolean;
}

export function getSettingsForProductButtons(mspAccounts: IAccount[], accountsNames: IAccount[], selectedAccount: IAccount | undefined, mspAccountLoggedIn: IAccount, parentProducts: IAccountProducts, productsToDisplay: IProductFamily[], accountsProducts: IAccountProducts[]): IProductButtonsSettings {
  let shouldShowAssignedSubpartnersButton: boolean = false;
  let shouldShowAssignButton: boolean = false;
  let shouldShowActivateButton: boolean = false;
  let shouldBillingExclusionsButtonBeVisible: boolean = false;
  let productsToAssignActivateFound: IProduct[] = [];
  let shouldAssignButtonBeDisabled: boolean = true;
  let shouldActivateButtonBeDisabled: boolean = true;
  let shouldBillingExclusionsButtonBeDisabled: boolean = true;
  let shouldShowBillingExclusionsButton: boolean = false;
  let shouldShowChangeServicesButton: boolean = false;
  let shouldDisableChangeServicesButton: boolean = false;

  const isAdmin = userHasRole(UserRole.Admin, mspAccountLoggedIn);
  const isTech = userHasRole(UserRole.Tech, mspAccountLoggedIn);
  const isTechDnd = userHasRole(UserRole.TechDoNotDelete, mspAccountLoggedIn);

  if (selectedAccount) {
    // Determine Change Service(s) button toggled/visibility state
    ({ shouldDisableChangeServicesButton, shouldShowChangeServicesButton } = determineChangeServicesButtonState(isAdmin, selectedAccount!, accountsProducts, parentProducts));

    if (selectedAccount.type === MspType.Partner && isNotFinance(mspAccountLoggedIn)) {
      shouldShowAssignedSubpartnersButton = true;
    } else if (selectedAccount.type === MspType.Subpartner && isAdmin) {
      if (mspAccountLoggedIn.type === MspType.Partner) {
        shouldShowAssignButton = true;
        if (parentProducts.accountId === selectedAccount.closestParentId) {
          const productsAvailableToAssign = getProductsAvailableToAssign(parentProducts.productFamilies, productsToDisplay);
          productsToAssignActivateFound = productsAvailableToAssign;
          shouldAssignButtonBeDisabled = productsAvailableToAssign.length < 1;
        } else {
          shouldShowAssignButton = false;
        }
      }
    } else if (selectedAccount.type === MspType.Customer) {
      if (isAdmin || isTech || isTechDnd) {
        let parentAccount;
        shouldShowActivateButton = isAdmin;
        shouldShowBillingExclusionsButton = (mspAccountLoggedIn.type === MspType.Partner || mspAccountLoggedIn.type === MspType.Subpartner) && (isAdmin || isTech || isTechDnd);
        if (parentProducts.accountId === selectedAccount.closestParentId && selectedAccount.closestParentId) {
          parentAccount = findAccountById(mspAccounts, accountsNames, selectedAccount.closestParentId);
          const productsAvailableToActivate = getProductsAvailableToActivate(parentProducts.productFamilies, productsToDisplay, parentAccount);
          productsToAssignActivateFound = productsAvailableToActivate;
          shouldActivateButtonBeDisabled = productsAvailableToActivate.length < 1;

          const auditButtonState = getAuditUsersButtonState(productsToDisplay, parentProducts.productFamilies);
          shouldBillingExclusionsButtonBeDisabled = !auditButtonState.enabled;
          shouldBillingExclusionsButtonBeVisible = auditButtonState.visible;
        } else {
          shouldShowActivateButton = false;
          shouldShowBillingExclusionsButton = false;
        }
      }
    }
  }
  return { shouldShowAssignedSubpartnersButton, shouldShowAssignButton, shouldShowActivateButton, shouldBillingExclusionsButtonBeVisible, productsToAssignActivateFound, shouldAssignButtonBeDisabled, shouldActivateButtonBeDisabled, shouldBillingExclusionsButtonBeDisabled, shouldShowBillingExclusionsButton, shouldShowChangeServicesButton, shouldDisableChangeServicesButton };
}
