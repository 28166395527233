import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Select } from "@cuda-networks/bds-core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import IAccount from "../../models/IAccount";
import { IAppState } from "../../store/store";
import { enterKey, handleBackdropClick } from "../../utility";
import { MenuItem } from "@material-ui/core";
import { setProductsToChangeFromAction } from "../../actions/productActions";

interface IManageProductsServicesChooseToProps {
  onCancel: () => void;
  showDialog: boolean;
  isActionInProgress: boolean;
  onNext: () => void;
  onBack: () => void;
  selectedAccount: IAccount;
}

interface ISection {
  id: number;
  availableOptions: { value: string; label: string }[];
  selectedOptionValue: string;
}

const ManageProductsServicesChooseTo: React.FC<IManageProductsServicesChooseToProps> = ({ showDialog, onCancel, onBack, onNext, isActionInProgress, selectedAccount }) => {
  const dispatch = useDispatch();
  const [selectedOptionValue, setSelectedOptionValue] = useState("none");
  const productState = useSelector((state: IAppState) => state.productState);
  const accountProducts = productState.accountsProducts;
  const products = accountProducts.filter(a => a.accountId === selectedAccount.id);

  let activeServiceFamilies: { value: string; label: string }[] = [];
  activeServiceFamilies.push({ value: "none", label: "Select a Service Family..." });

  products.forEach(product => {
    product.productFamilies.forEach(productFamily => {
      productFamily.products.forEach(innerProduct => {
        activeServiceFamilies.push({ value: innerProduct.id, label: innerProduct.name });
      });
    });
  });

  const handleProductsDropdownChange = (newValue: string) => {
    setSelectedOptionValue(newValue);
  };

  function onAfterCancel() {
    // reset selected products/services
    dispatch(setProductsToChangeFromAction([]));
  }

  return (
    <div>
      <Dialog onKeyUp={(event: any) => enterKey(event, onNext)} disableEscapeKeyDown={isActionInProgress} className="showUnassignDialog" data-testid="showManageProductsServicesToDialog" open={showDialog} onClose={(event: EventSource, reason: string) => handleBackdropClick(event, reason, onCancel)} maxWidth={false}>
        <div className="dialogWrapper">
          <DialogTitle data-testid="showManageProductsChanngeServicesToTitle" id="alert-dialog-title" style={{ backgroundColor: "primary" }}>
            Change of Service
          </DialogTitle>
          <DialogContent data-testid="showManageProductsChangeServicesToMessage">
            <p>
              <strong>TO</strong>
            </p>
            <p>Select an active service you wish to change. The "TO" service selected below shall have the previous step's service(s) changed to them.</p>
            <p>
              <div>
                <div className="sectionWrapper">
                  <div>
                    <strong>ACTIVE SERVICE FAMILY</strong>
                  </div>
                  <Select onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleProductsDropdownChange(e.target.value)}>
                    {activeServiceFamilies
                      .filter(option =>
                        // Exclude the default option if a selection has been made
                        selectedOptionValue !== "none" ? option.value !== "none" : true,
                      )
                      .map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                  </Select>
                </div>
              </div>
            </p>
          </DialogContent>
          <DialogActions style={{ paddingTop: "18px", paddingBottom: "18px" }}>
            <Button
              data-testid="manageProductsServicesToCancelButton"
              variant="text"
              size="large"
              onClick={() => {
                onCancel();
                onAfterCancel();
              }}
              disabled={isActionInProgress}
            >
              CANCEL
            </Button>
            <Button data-testid="manageProductsServicesToBackButton" size="large" onClick={() => onBack()} isLoading={isActionInProgress}>
              BACK
            </Button>
            <Button data-testid="manageProductsServicesToNextButton" color="primary" size="large" onClick={() => onNext()} isLoading={isActionInProgress}>
              NEXT
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};

export default ManageProductsServicesChooseTo;
