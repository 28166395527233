import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@cuda-networks/bds-core";
import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../store/store";
import { enterKey, handleBackdropClick } from "../../utility";
import { Grid, Typography, IconButton, Link, MenuItem, Tooltip, TextField } from "@material-ui/core";
import { IManageProductsServicesChooseFromProps, getActiveEssentialsProducts, addSection as addProductsSection, removeProductServiceSection, onAddProductService, onActiveServiceSelected, getSelectedProduct } from "./ManageProductsServicesChooseFromUtils";
import { setProductsToChangeFromAction } from "../../actions/productActions";
import IProduct from "../../models/Products/IProduct";
import { Delete } from "@material-ui/icons";

const ManageProductsServicesChooseFrom: React.FC<IManageProductsServicesChooseFromProps> = ({ showDialog, onCancel, onNext, isActionInProgress, selectedAccount }) => {
  const sections = useSelector((state: IAppState) => state.productState.productsToChangeFrom);
  const dispatch = useDispatch();
  const [idSeed, setIdSeed] = useState(0);
  const [nextDisabled, setNextDisabled] = useState(true);
  // cache the original list (that also contains the default selection so it can be reused later)
  const [initialProducts, setInitialProducts] = useState<{ value: string; category: string; name: string }[]>([]);
  // in use products list (where products can be added or removed)
  const [products, setProducts] = useState<IProduct[]>([]);
  const productState = useSelector((state: IAppState) => state.productState);

  useEffect(() => {
    const { accountEssentialProducts, initialAccountProducts: initialProducts } = getActiveEssentialsProducts(productState.accountsProducts, selectedAccount.id);
    setInitialProducts(initialProducts);
    setProducts(accountEssentialProducts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccount]);

  useEffect(() => {
    if (initialProducts.length <= 0) {
      return;
    }

    if (sections.length < 1) {
      addNewProductService(); //add the first section by default
      return;
    }

    //sections existed already (we navigated back) -> restore idSeed
    setIdSeed(sections.length);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialProducts]);

  useMemo(() => {
    if (sections.length >= 1 && !sections.find(section => section.selectedOptionValue === "none")) {
      setNextDisabled(false);
    }
  }, [sections]);

  const removeProductServiceSectionInternal = (sectionId: number) => {
    // make available the current selection for other dropdowns
    const updatedSections = removeProductServiceSection(sections, sectionId);
    dispatch(setProductsToChangeFromAction(updatedSections));
    if (updatedSections.length === 0) {
      setNextDisabled(true);
    } else {
      // make sure something is selected
      updatedSections.find(s => s.selectedOptionValue !== "none") ? setNextDisabled(false) : setNextDisabled(true);
    }

    setIdSeed(idSeed - 1);
  };

  const onActiveServiceSelectedInternal = (id: number, newValue: string) => {
    if (!sections || newValue === "none") return;
    const updatedSections = onActiveServiceSelected(id, newValue, sections);
    dispatch(setProductsToChangeFromAction(updatedSections));
  };

  const addNewProductService = () => {
    setNextDisabled(true);
    onAddProductService(idSeed, initialProducts, sections);
    const newSections = addProductsSection(idSeed, initialProducts, sections);
    dispatch(setProductsToChangeFromAction(newSections));
    setIdSeed(idSeed + 1);
  };

  const onAddProductServiceInternal = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault(); // Prevent default link behavior
    addNewProductService();
  };

  return (
    <div>
      <Dialog onKeyUp={(event: any) => enterKey(event, onNext)} disableEscapeKeyDown={isActionInProgress} className="showUnassignDialog" data-testid="showManageProductsServicesFromDialog" open={showDialog} onClose={(event: EventSource, reason: string) => handleBackdropClick(event, reason, onCancel)} fullWidth>
        <div className="dialogWrapper">
          <DialogTitle data-testid="showManageProductsChanngeServicesFromTitle" id="alert-dialog-title" style={{ backgroundColor: "primary" }}>
            Change of Service(s)
          </DialogTitle>
          <DialogContent data-testid="showManageProductsChangeServicesFromMessage">
            <p>
              <strong>FROM</strong>
            </p>
            <p>Select an active service(s) you wish to change. The "FROM" service(s) selected below shall be changed to the "TO" service selected in the next step.</p>
            <p>{/* Dynamic Products/Service family selector */}</p>
            <p></p>
            <div>
              {sections.map(section => (
                <div key={section.id} className="sectionWrapper">
                  <Grid container>
                    <Grid item xs={11} style={{ paddingRight: 10 }}>
                      <div style={{ marginBottom: "5px", marginLeft: "2px" }}>
                        <Typography variant="body2" style={{ fontWeight: "bold", fontSize: "12px" }}>
                          ACTIVE SERVICE / PRODUCT
                        </Typography>
                      </div>
                      <TextField select data-testid="productDropdown" value={getSelectedProduct(section)} onChange={(e: { target: { value: string } }) => onActiveServiceSelectedInternal(section.id, e.target.value)} variant="outlined" size="small" fullWidth>
                        {section.availableOptions.map(option => (
                          <MenuItem disabled={option.value === "none"} key={option?.value ? option.value : ""} value={option?.value ? option.value : ""}>
                            {option.category}
                          </MenuItem>
                        ))}
                      </TextField>
                      <p></p>
                      <div style={{ marginLeft: "2px" }}>
                        <Typography variant="body2" style={{ fontWeight: "bold", fontSize: "12px", marginBottom: 5 }}>
                          ACTIVE SERIAL
                        </Typography>
                        <div style={{ maxWidth: "100%" }}>
                          {products?.filter(p => p.id === section?.selectedOptionValue)[0]?.subname ? (
                            <Grid container style={{ marginTop: -15 }}>
                              <Grid item xs="auto">
                                <p>{products?.filter(p => p?.id === section?.selectedOptionValue)[0]?.subname}</p>
                              </Grid>
                              <Grid item xs="auto" style={{ justifyContent: "flex-end", marginLeft: "auto" }}>
                                <p>Serial#: {products?.filter(p => p.id === section.selectedOptionValue)[0]?.serial}</p>
                              </Grid>
                            </Grid>
                          ) : (
                            "none"
                          )}
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={1} style={{ justifyContent: "left", marginRight: "auto", paddingLeft: 15 }}>
                      <Tooltip title="Delete">
                        <IconButton onClick={() => removeProductServiceSectionInternal(section.id)} style={{ padding: "2px" }} data-testid="servicesSectionDeleteButton" disabled={sections.length === 1}>
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </div>
              ))}
            </div>
            {/* Add more products/services sections */}
            <Link
              data-testid="addAnotherProductLink"
              onClick={onAddProductServiceInternal}
              style={{
                pointerEvents: sections?.length === initialProducts?.length - 1 /*(since there is also the first "Select an option" value which is not a valid value that the user can select)*/ ? "none" : "auto",
                opacity: sections?.length === initialProducts?.length - 1 ? 0.5 : 1,
              }}
              variant="body2"
              target="_blank"
              href="#/"
            >
              + Add Another Product / Service
            </Link>
          </DialogContent>
          <DialogActions style={{ paddingTop: "18px", paddingBottom: "18px" }}>
            <Button
              data-testid="manageProductsChangeServicesFromCancelButton"
              variant="text"
              size="large"
              onClick={() => {
                onCancel();
              }}
              disabled={isActionInProgress}
            >
              CANCEL
            </Button>
            <Button disabled={nextDisabled} data-testid="manageProductsServicesFromNextButton" color="primary" size="large" onClick={() => onNext()} isLoading={isActionInProgress}>
              NEXT
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};

export default ManageProductsServicesChooseFrom;
