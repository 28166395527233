import IAccount from "../../models/IAccount";
import IAccountProducts from "../../models/Products/IAccountProducts";
import IProduct from "../../models/Products/IProduct";
import MspType from "../../models/MspType";

export interface IManageProductsServicesChooseFromProps {
  onCancel: () => void;
  showDialog: boolean;
  isActionInProgress: boolean;
  onNext: () => void;
  selectedAccount: IAccount;
}

export interface IChangeProductService {
  id: number;
  availableOptions: { value: string; category: string; name: string }[];
  selectedOptionValue: string;
}

const selectProductDefaultValue = "-- Select a Product / Service --";

export const getActiveEssentialsProducts = (accountProducts: IAccountProducts[], selectedAccountId: number) => {
  let accountEssentialProducts: IProduct[] = [];
  let initialAccountProducts: { value: string; category: string; name: string }[] = [];

  initialAccountProducts.push({ value: "none", category: selectProductDefaultValue, name: selectProductDefaultValue });

  const availableAccountProducts = accountProducts.filter(a => a.accountId === selectedAccountId);
  availableAccountProducts.forEach(accountProduct => {
    accountProduct.productFamilies.forEach(productFamily => {
      productFamily.products.forEach(product => {
        // only "Essentials Services" are allowed (display name is "Barracuda Email Protection")
        if (product.type !== "Essentials Services") {
          return;
        }
        // the product must be active
        if (product.status !== "Active") {
          return;
        }

        if (!product.serials || product.serials.length <= 0) {
          return;
        }
        // active products, with at least one "Active" serial
        const activeSerials = product.serials.filter(serial => serial.status === "Active");
        if (activeSerials.length <= 0) {
          return;
        }
        accountEssentialProducts.push(product);
        initialAccountProducts.push({ value: product.id, category: product.name, name: product.subname });
      });
    });
  });

  return { accountEssentialProducts, initialAccountProducts };
};

export const determineChangeServicesButtonState = (isAdmin: boolean, selectedAccount: IAccount, accountsProducts: IAccountProducts[], parentProducts: IAccountProducts) => {
  // Role admin can change services, but only if an account is selected
  const isAccountSelected = selectedAccount.type === MspType.Customer;
  let shouldShowChangeServicesButton = isAccountSelected && isAdmin;
  let shouldDisableChangeServicesButton = shouldShowChangeServicesButton; // shoud this be false, in case of not being eligible, the button will he hidden instead so there's not reason to also disable it)

  if (shouldShowChangeServicesButton === true) {
    // only if the button is not already hidden by the above logic
    const { accountEssentialProducts } = getActiveEssentialsProducts(accountsProducts, selectedAccount?.id ?? 0);
    if (parentProducts.accountId === selectedAccount?.closestParentId) {
      // hidden, if there are no active essential products available
      if (!accountEssentialProducts || accountEssentialProducts.length === 0) {
        console.log("Change Service(s) unavailable: Account has no active Essentials Services products");
        shouldShowChangeServicesButton = false;
      } else if (
        !parentHasAvailableEssentialProduct(
          parentProducts,
          accountsProducts.filter(acc => acc.accountId === selectedAccount?.id),
        )
      ) {
        //disabled, if all of the parent’s Essentials products have already been activated on the account (serials exist)
        console.log("Change Service(s) disabled: all of the parent’s Essentials products have already been activated on the account (serials exist)");
        shouldDisableChangeServicesButton = true;
      } else {
        // enable the button
        shouldDisableChangeServicesButton = false;
      }
    }
  }
  return { shouldDisableChangeServicesButton, shouldShowChangeServicesButton };
};

const accountHasSerial = (accountsProducts: IAccountProducts[], serialToSearch: string) => {
  return accountsProducts.some(accountProducts => {
    return accountProducts.productFamilies.some(productFamily => {
      return productFamily.products.some(product => {
        return product.serials.some(accountSerial => accountSerial.serial === serialToSearch);
      });
    });
  });
};

/**
 * Determine if a service can be changed, depending on availability of serials for the  Essentials Services products
 * @param parentProducts
 * @param currentAccountProducts
 * @returns
 */
export const parentHasAvailableEssentialProduct = (parentProducts: IAccountProducts, currentAccountProducts: IAccountProducts[]) => {
  const parentProductEssentialsFamily = parentProducts.productFamilies.find(f => f.productType === "Essentials Services");
  if (!parentProductEssentialsFamily) {
    return false;
  }

  let hasAvailableEssentialProduct = false;
  parentProductEssentialsFamily.products.forEach(parentEssentialsProduct => {
    if (hasAvailableEssentialProduct) {
      // make sure not to reset the flag once set
      return;
    }
    // the product must be active
    if (parentEssentialsProduct.status !== "Active") {
      return;
    }

    if (!parentEssentialsProduct.serials || parentEssentialsProduct.serials.length <= 0) {
      // active product with no serials
      hasAvailableEssentialProduct = true;
      return;
    }
    parentEssentialsProduct.serials.forEach(essentialsSerial => {
      const currentEssentialsSerial = essentialsSerial.serial;
      const accountAlreadyHasSerial = accountHasSerial(currentAccountProducts, currentEssentialsSerial);
      if (essentialsSerial.status === "Active") {
        if (accountAlreadyHasSerial) {
          // has at least one active serial, that is not already added to the account...
          hasAvailableEssentialProduct = true;
        }
      } else if (!accountAlreadyHasSerial) {
        // the serial is not active (it doesn't need to belong to the account)
        hasAvailableEssentialProduct = true;
      }
      if (hasAvailableEssentialProduct) {
        hasAvailableEssentialProduct = parentProductEssentialsFamily.products.find(product => product.serials.find(s => s.serial !== currentEssentialsSerial) !== undefined) !== undefined;
      }
    });
  });

  return hasAvailableEssentialProduct;
};

export const getSelectedProduct = (section: IChangeProductService) => {
  return section.selectedOptionValue || "";
};

export const addSection = (idSeed: number, initialProducts: { value: string; category: string; name: string }[], sections: IChangeProductService[]) => {
  let remainingActiveServices = [...initialProducts];
  sections.forEach(section => {
    if (section.selectedOptionValue !== "none") {
      remainingActiveServices = remainingActiveServices.filter(option => option.value !== section.selectedOptionValue);
    }
  });

  const newSection: IChangeProductService = {
    id: idSeed,
    selectedOptionValue: "none", // Initialize with the placeholder or first option value
    availableOptions: remainingActiveServices,
  };
  return [...sections, newSection];
};

export const removeProductServiceSection = (sections: IChangeProductService[], sectionId: number) => {
  // make available the current selection for other dropdowns
  const selectedSection = sections.find(section => section.id === sectionId);
  const selectedValue = selectedSection?.selectedOptionValue;
  const selectedName = selectedSection?.availableOptions.find(option => option.value === selectedValue)?.name || "";
  const selectedCategory = selectedSection?.availableOptions.find(option => option.value === selectedValue)?.category || "";

  // remove the current section and update the other sections to add the current selection
  const updatedSections = sections
    .filter(section => section.id !== sectionId)
    .map(section => {
      if (selectedValue && selectedValue !== "none") {
        if (section.availableOptions.find(option => option.value === selectedValue) === undefined) {
          section.availableOptions.push({ value: selectedValue || "", name: selectedName || "", category: selectedCategory || "" });
        }
      }
      return section;
    });

  //refresh ids of sections (sections can be removed from any position and we want to keep the ids consecutive)
  updatedSections.forEach(section => (section.id = updatedSections.indexOf(section)));
  return updatedSections;
};

export const onAddProductService = (idSeed: number, initialProducts: { value: string; category: string; name: string }[], sections: IChangeProductService[]) => {
  addSection(idSeed, initialProducts, sections);
};

export const onActiveServiceSelected = (sectionId: number, newValue: string, sections: IChangeProductService[]) => {
  // obtain the previous selection of this dropdown
  const currentServiceDropdown = sections.find(section => section.id === sectionId);
  const previousValue = currentServiceDropdown?.selectedOptionValue;
  const previousCategory = currentServiceDropdown?.availableOptions.find(option => option.value === previousValue)?.category || "";
  const previousName = currentServiceDropdown?.availableOptions.find(option => option.value === previousValue)?.name || "";

  // update the selected value for the current section
  const updatedSections = sections.map(section => {
    let newFilteredOptions = section.availableOptions;
    let valueToSelect;
    if (section.id === sectionId) {
      // current dropdown
      valueToSelect = newValue;
    } else {
      // other dropdowns
      // add back the previous selection to all dropdowns that don't have it
      // remove the currently selected value
      valueToSelect = section.selectedOptionValue;
      if (previousValue && previousValue !== "none" && !newFilteredOptions.find(option => option.value === previousValue)) {
        newFilteredOptions.push({ value: previousValue, category: previousCategory, name: previousName });
      }
      if (newValue !== "none") {
        newFilteredOptions = newFilteredOptions.filter(option => option.value !== newValue);
      }
    }
    return { ...section, availableOptions: newFilteredOptions, selectedOptionValue: valueToSelect };
  });
  return updatedSections;
};
