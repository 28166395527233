import { Button, Tooltip, CircularProgress } from "@cuda-networks/bds-core";
import React from "react";
import { setNoPoinerEvents } from "../../utility";
import { Link } from "react-router-dom";

interface IProductsButtonProps {
  isLoading: boolean;
  hasData: boolean;
  tooltip: string;
  disabled: boolean;
  label: string;
  handleClick: () => void;
  progressDataTestId: string;
  buttonDataTestId: string;
  showLoading: boolean;
  isLink?: boolean;
  toUrl?: string;
  linkTitle?: string;
}

const ProductsButton: React.FC<IProductsButtonProps> = ({ isLoading, hasData, tooltip, disabled, label, handleClick, progressDataTestId, buttonDataTestId, showLoading, isLink, toUrl, linkTitle }) => {
  if (isLoading && showLoading) {
    return <CircularProgress data-testid={progressDataTestId} style={{ marginRight: "15px" }}></CircularProgress>;
  } else {
    if (hasData) {
      return (
        <div className={disabled ? "cursorNotAllowed" : ""}>
          <Tooltip title={tooltip} disableHoverListener={!disabled} placement="bottom-start">
            <span>
              <Button data-testid={buttonDataTestId} className={setNoPoinerEvents(disabled)} color={label === "USER BILLING LIST" || label === "CHANGE SERVICE(S)" ? "secondary" : "primary"} disabled={disabled} variant={"contained"} size={"large"} style={{ marginRight: "15px" }} onClick={handleClick} component={isLink ? Link : undefined} to={toUrl!}>
                {label}
              </Button>
            </span>
          </Tooltip>
        </div>
      );
    } else {
      return null;
    }
  }
};

export default ProductsButton;
