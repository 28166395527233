import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { activateProductsForAccountAction, assignProductsForAccountAction, cancelGetSubpartnersWithProductsAssignedAction, clearProductsFromSubpartnersWithProducts, getParentMspProductsAction, getProductsAction, getSubpartnersWithAssignedProductsAction, setLoadingProductsForSubpartner, setProductsToChangeFromAction } from "../../actions/productActions";
import IProduct from "../../models/Products/IProduct";
import { IAppState } from "../../store/store";
import ActivateAssignProductDialog from "./ActivateAssignProductDialog";
import AssignedProductsDialog from "./AssignedProducts/AssignedProductsDialog";
import ProductFamily, { getProductTypeNameToDisplay } from "../../models/Products/ProductFamily";
import { getSettingsForProductButtons, IProductButtonsSettings } from "../../businessLogic/components/Products/ProductsButtonActions";
import ProductsButton from "./ProductsButton";
import { setSnackBarMessage } from "../../actions/generalActions";
import { filterAccountsAction } from "../../actions/accountActions";
import ActionMessageType from "../../models/ActionMessageType";
import { ActionMessages, ActionTypes } from "../../actions/ActionTypes";
import { productIsBBS } from "../../Utilities/productsHelper";
import { useNavigate } from "react-router-dom";
import { UrlFilterExtractor } from "../Accounts/FilterAccounts/UrlFilterExtractor";
import ManageProductsServicesChooseFrom from "./ManageProductsServicesChooseFrom";
import ManageProductsServicesChooseTo from "./ManageProductsServicesChooseTo";

const ProductsButtonActions: React.FC = () => {
  const dispatch = useDispatch();

  const [showActivateProductDialog, setShowActivateProductDialog] = useState(false);
  const [showAssignProductDialog, setShowAssignProductDialog] = useState(false);
  const [showAssignedSubpartnersProductsDialog, setShowAssignedSubpartnersProductsDialog] = useState(false);
  const [actionInProgress, setActionInProgress] = useState(false);
  const [productsToAssignActivate, setProductsToAssignActivate] = useState<IProduct[]>([]);

  const loadingHasSubpartnersWithProducts = useSelector((state: IAppState) => state.productState.loadingHasSubpartnersWithProducts);
  const hasSubpartnersWithProducts = useSelector((state: IAppState) => state.productState.hasSubpartnersWithProducts);
  const selectedAccount = useSelector((state: IAppState) => state.accountState.selectedAccount);
  const mspAccounts = useSelector((state: IAppState) => state.accountState.mspAccounts);
  const accountsNames = useSelector((state: IAppState) => state.accountState.accountsNames);
  const accountsProducts = useSelector((state: IAppState) => state.productState.accountsProducts);
  const mspAccountLoggedIn = useSelector((state: IAppState) => state.generalState.mspAccountLoggedIn);
  const parentProducts = useSelector((state: IAppState) => state.productState.parentProductsFamilies);
  const productsToDisplay = useSelector((state: IAppState) => state.productState.productsToDisplay);
  const loadingParentMspProducts = useSelector((state: IAppState) => state.productState.loadingParentMspProducts);
  const accountsOrders = useSelector((state: IAppState) => state.productState.accountsOrders);
  const filters = new UrlFilterExtractor(window.location.search, accountsOrders).extractFilters();
  const [showAssignedSubpartnersButton, setShowAssignedSubpartnersButton] = useState(false);

  const [showAssignButton, setShowAssignButton] = useState(false);
  const [isAssignButtonDisabled, setIsAssignButtonDisabled] = useState(false);
  const assignButtonToolTip = "No more products to assign";
  const manageProductsServicesDisabledToolTip = "No valid options to change to.";

  const [showActivateButton, setShowActivateButton] = useState(false);
  const [isActivateButtonDisabled, setIsActivateButtonDisabled] = useState(false);
  const activateButtonToolTip = "No more products to activate";

  const auditUsersButtonToolTip = `No ${getProductTypeNameToDisplay(ProductFamily.ESSENTIALS_SERIVICES)} or Barracuda Content Shield Plus serials Active`;
  const [shouldShowBillingExclusionButton, setShouldShowBillingExclusionButton] = useState(false);
  const [isBillingExclusionsButtonVisible, setIsBillingExclusionsButtonVisible] = useState(false);
  const [isBillingExclusionsButtonDisabled, setIsBillingExclusionsButtonDisabled] = useState(false);
  const navigate = useNavigate();

  const [shouldShowChangeServicesButton, setShouldShowChangeServicesButton] = useState(false);
  const [showManageProductsServicesFromDialog, setShowManageProductsServicesFromDialog] = useState(false);
  const [showManageProductsServicesToDialog, setShowManageProductsServicesToDialog] = useState(false);
  const [disableChangeServicesButton, setDisableChangeServicesButton] = useState(true);

  const handleOpenActivateProduct = () => {
    setShowActivateProductDialog(!showActivateProductDialog);
  };

  const handleOpenAssignProduct = () => {
    setShowAssignProductDialog(!showAssignProductDialog);
  };

  const handleOpenAssignedProducts = () => {
    dispatch(getSubpartnersWithAssignedProductsAction());
    setShowAssignedSubpartnersProductsDialog(!showAssignedSubpartnersProductsDialog);
  };

  useEffect(() => {
    const handlePopState = (event: any) => {
      setShowActivateProductDialog(false);
      setShowAssignProductDialog(false);
      setShowAssignedSubpartnersProductsDialog(false);
    };
    window.addEventListener("popstate", handlePopState);
    return () => window.removeEventListener("popstate", handlePopState);
  }, []);

  useEffect(() => {
    const productButtonsSettings: IProductButtonsSettings = getSettingsForProductButtons(mspAccounts, accountsNames, selectedAccount, mspAccountLoggedIn, parentProducts, productsToDisplay, accountsProducts);
    setShowAssignedSubpartnersButton(productButtonsSettings.shouldShowAssignedSubpartnersButton);
    setProductsToAssignActivate(productButtonsSettings.productsToAssignActivateFound);
    setShowAssignButton(productButtonsSettings.shouldShowAssignButton);
    setIsAssignButtonDisabled(productButtonsSettings.shouldAssignButtonBeDisabled);
    setShowActivateButton(productButtonsSettings.shouldShowActivateButton);
    setIsActivateButtonDisabled(productButtonsSettings.shouldActivateButtonBeDisabled);
    setShouldShowBillingExclusionButton(productButtonsSettings.shouldShowBillingExclusionsButton);
    setIsBillingExclusionsButtonVisible(productButtonsSettings.shouldBillingExclusionsButtonBeVisible);
    setIsBillingExclusionsButtonDisabled(productButtonsSettings.shouldBillingExclusionsButtonBeDisabled);
    setDisableChangeServicesButton(productButtonsSettings.shouldDisableChangeServicesButton);
    setShouldShowChangeServicesButton(productButtonsSettings.shouldShowChangeServicesButton);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccount, parentProducts, productsToDisplay, accountsNames]);

  const activateProduct = (product: IProduct) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(activateProductsForAccountAction(selectedAccount, product));
      resolve(result);
    });

  const handleActivateProduct = (product: IProduct) => {
    setActionInProgress(true);
    activateProduct(product).then(result => {
      setActionInProgress(false);
      if (result) {
        dispatch(setSnackBarMessage({ message: ActionMessages[ActionTypes.ActivateProduct].infoMessage, type: ActionMessageType.Info }));
        setShowActivateProductDialog(!showActivateProductDialog);
        if (selectedAccount) {
          if (productIsBBS(product)) {
            dispatch(getParentMspProductsAction(selectedAccount));
          }
          dispatch(getProductsAction(selectedAccount));
        }
        if (filters?.noProducts) {
          dispatch(filterAccountsAction(filters));
          navigate("/filters" + window.location.search);
        }
      }
    });
  };

  const assignProduct = (product: IProduct) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(assignProductsForAccountAction(selectedAccount, product));
      resolve(result);
    });

  const handleAssignProduct = (product: IProduct) => {
    setActionInProgress(true);
    assignProduct(product).then(result => {
      setActionInProgress(false);
      if (result) {
        dispatch(setSnackBarMessage({ message: ActionMessages[ActionTypes.AssignProduct].successMessage, type: ActionMessageType.Success }));
        setShowAssignProductDialog(!showAssignProductDialog);
        if (selectedAccount) {
          if (productIsBBS(product)) {
            dispatch(getParentMspProductsAction(selectedAccount));
          }
          dispatch(getProductsAction(selectedAccount));
        }
      }
    });
  };

  const handleOnCancelActivate = () => {
    setShowActivateProductDialog(!showActivateProductDialog);
  };

  const handleOnCancelAssign = () => {
    setShowAssignProductDialog(!showAssignProductDialog);
  };

  const handleOnCancelAssigned = () => {
    dispatch(cancelGetSubpartnersWithProductsAssignedAction());
    dispatch(setLoadingProductsForSubpartner(false));
    dispatch(clearProductsFromSubpartnersWithProducts());
    setShowAssignedSubpartnersProductsDialog(!showAssignedSubpartnersProductsDialog);
  };

  const handleOpenManageProductsServicesFromStep = () => {
    setShowManageProductsServicesFromDialog(true);
  };

  const handleCancelOpenManageProductsServicesFromDialog = () => {
    dispatch(setProductsToChangeFromAction([]));
    setShowManageProductsServicesFromDialog(false);
  };

  const handleOpenManageProductsServicesToStep = () => {
    setShowManageProductsServicesFromDialog(false);
    setShowManageProductsServicesToDialog(true);
  };

  const handleManageProductServicesToStepBack = () => {
    setShowManageProductsServicesFromDialog(true);
    setShowManageProductsServicesToDialog(false);
  };

  const handleCancelOpenManageProductsServicesToDialog = () => {
    setShowManageProductsServicesToDialog(false);
  };

  const handleOpenManageProductsServicesConfirmStep = () => {
    alert("Not implemented yet");
  };

  const userBilling = `user-billing` + window.location.search;
  return (
    <div style={{ display: "flex" }}>
      {shouldShowBillingExclusionButton && <ProductsButton isLoading={loadingHasSubpartnersWithProducts} hasData={isBillingExclusionsButtonVisible} tooltip={auditUsersButtonToolTip} disabled={isBillingExclusionsButtonDisabled} label={"USER BILLING LIST"} handleClick={() => {}} progressDataTestId={"loadingBillingListButton"} buttonDataTestId={"billingListButton"} showLoading={false} isLink={true} toUrl={userBilling} linkTitle="User Billing" />}
      {showAssignedSubpartnersButton && <ProductsButton isLoading={loadingHasSubpartnersWithProducts} hasData={hasSubpartnersWithProducts} tooltip={""} disabled={false} label={"SUBPARTNERS' CATALOG"} handleClick={handleOpenAssignedProducts} progressDataTestId={"loadingHasSubpartnersWithProducts"} buttonDataTestId={"assignedSubpartnersButton"} showLoading={true} />}
      {showAssignButton && <ProductsButton isLoading={loadingParentMspProducts} hasData={parentProducts.accountId === selectedAccount?.closestParentId && parentProducts.productFamilies.length > 0} tooltip={assignButtonToolTip} disabled={isAssignButtonDisabled} label={"ASSIGN"} handleClick={handleOpenAssignProduct} progressDataTestId={"loadingAvailableProductsToAssign"} buttonDataTestId={"assignButton"} showLoading={true} />}
      {showActivateButton && <ProductsButton isLoading={loadingParentMspProducts} hasData={parentProducts.accountId === selectedAccount?.closestParentId && parentProducts.productFamilies.length > 0} tooltip={activateButtonToolTip} disabled={isActivateButtonDisabled} label={"ACTIVATE"} handleClick={handleOpenActivateProduct} progressDataTestId={"loadingAvailableProductsToActivate"} buttonDataTestId={"activateButton"} showLoading={true} />}
      {showActivateProductDialog && <ActivateAssignProductDialog productsToActivate={productsToAssignActivate} actionInProgress={actionInProgress} showDialog={showActivateProductDialog} onCancel={handleOnCancelActivate} onSubmit={product => handleActivateProduct(product)} dialogTitle={"ACTIVATE PRODUCT/SERVICE"} />}
      {showAssignProductDialog && <ActivateAssignProductDialog productsToActivate={productsToAssignActivate} actionInProgress={actionInProgress} showDialog={showAssignProductDialog} onCancel={handleOnCancelAssign} onSubmit={product => handleAssignProduct(product)} dialogTitle={"ASSIGN PRODUCT/SERVICE"} />}
      {showAssignedSubpartnersProductsDialog && <AssignedProductsDialog showDialog={showAssignedSubpartnersProductsDialog} onCancel={handleOnCancelAssigned} />}
      {shouldShowChangeServicesButton && <ProductsButton isLoading={false} hasData={parentProducts.accountId === selectedAccount?.closestParentId && parentProducts.productFamilies?.length > 0} tooltip={manageProductsServicesDisabledToolTip} label={"CHANGE SERVICE(S)"} handleClick={handleOpenManageProductsServicesFromStep} disabled={disableChangeServicesButton} progressDataTestId={"loadingManageProductsServices"} buttonDataTestId={"ManageProductsServicesButton"} showLoading={false} />}
      {showManageProductsServicesFromDialog && selectedAccount && <ManageProductsServicesChooseFrom showDialog={showManageProductsServicesFromDialog} onCancel={handleCancelOpenManageProductsServicesFromDialog} isActionInProgress={actionInProgress} onNext={handleOpenManageProductsServicesToStep} selectedAccount={selectedAccount} />}
      {showManageProductsServicesToDialog && selectedAccount && <ManageProductsServicesChooseTo showDialog={showManageProductsServicesToDialog} onCancel={handleCancelOpenManageProductsServicesToDialog} isActionInProgress={actionInProgress} onNext={handleOpenManageProductsServicesConfirmStep} onBack={handleManageProductServicesToStepBack} selectedAccount={selectedAccount} />}
    </div>
  );
};

export default ProductsButtonActions;
